<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <!--        <VCardHints/>-->
        <v-row class="ma-4" v-if="messagesITNRW">
          <MessagesITNRW :messages="messagesITNRW"/>
        </v-row>
        <VCardMessages typeOfAccess="vermessungsstellen"/>
        <InfoMap :messages="messages"
                 typeOfAccess="vermessungsstellen"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import InfoMap from "@/components/InfoMap";
import VCardHints from "@/components/VCardHints";
import VCardMessages from "@/components/VCardMessages";
import MessagesITNRW from "@/components/MessagesITNRW";
import {API} from '@/config';

export default {

  components: {
    InfoMap,
    VCardHints,
    VCardMessages,
    MessagesITNRW
  },
  data() {
    return {
      messages: [],
    }
  },
  methods: {
    getMessages() {
      axios.post(`${API.url}${API.getMessages}`).then((response) => {
        this.messages = response.data.features;
      })

    },
  },
  computed: {
    messagesITNRW() {
      return this.messages.filter(message => (message.properties.kn === '05000000' && message.properties.sichtbarkeit !== 'opendata'));
    },

  },
  mounted() {
    this.getMessages();
  }
  ,


}
</script>
